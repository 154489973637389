<template>
	<section class="testimonial-section ptb-100">
		<div class="container">
			<div class="row justify-content-between align-items-center">
				<div class="col-md-6">
					<div class="section-heading mb-5">
						<h2 style="font-size: 2.8em; color: #315192;">Testimonials. <br />What clients say</h2>
						<p class="lead">
							Here you can find some testimonials from our clients and what they
							said about us.
						</p>
					</div>
				</div>
				<div v-if="show" class="col-md-5 owl-custom-nav-parent">
					<carousel
						ref="oc"
						:autoplay="true"
						:dots="false"
						class="owl-carousel owl-theme client-testimonial arrow-indicator owl-loaded owl-drag"
						:loop="true"
						:margin="30"
						:nav="true"
						:responsiveClass="true"
						:autoplayHoverPause="true"
						:lazyLoad="true"
						:items="1"
						:nav-text="navText"
					>
						<div
							v-for="(testimonial, index) in Testimonials"
							:key="index"
							class="item"
						>
							<div class="testimonial-quote-wrap">
								<div class="media author-info mb-3">
									<div v-if="testimonial.img !== null" class="author-img mr-3">
										<img
											v-if="testimonial.img !== null"
											:src="URL + testimonial.img.url"
											alt="client"
											class="img-fluid rounded-circle"
										/>
									</div>
									<div class="media-body">
										<h5 class="mb-0">{{ testimonial.client }}</h5>
										<span>{{ testimonial.company }}</span>
									</div>
									<button
										class="button btn solid-btn"
										v-b-modal="testimonial.client"
									>
										<i class="fas fa-play"></i>
									</button>

									<b-modal :id="testimonial.client" size="xl" title="Testimonials">
										<b-embed
											type="iframe"
											aspect="16by9"
											:src="testimonial.video"
											allowfullscreen

										></b-embed>

										<template #modal-footer="{ ok }">
											<b-button size="md" variant="success" @click="ok()">
												OK
											</b-button>
										</template>
									</b-modal>
								</div>
								<div class="client-say">
									<p style="overflow:auto;">
										<i class="fas fa-quote-left" style="font-size: 1.6em; color: #315192;"></i>
										{{ testimonial.text }}
									</p>
								</div>
							</div>
						</div>
					</carousel>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import axios from 'axios';

import carousel from 'vue-owl-carousel';

export default {
	name: 'Testimonial',
	components: { carousel },
	props: {
		URL: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			navText: ['‹', '›'],
			Testimonials: [],
			show: false,
		};
	},
	async mounted() {
		try {
			const response = await axios.get(`${this.URL}/testimonials`);
			this.Testimonials = await response.data;
			this.show = true;
		} catch (e) {
			this.error = e;
		}
	},
};
</script>

<style>
	.owl-custom-nav-parent .owl-carousel .owl-nav .owl-next:hover {
		color: #ffffff !important;
	}
	.owl-custom-nav-parent .owl-carousel .owl-nav .owl-prev:hover {
		color: #ffffff !important;
	}
</style>
