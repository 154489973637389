<template>
    <div v-if="show" class="overflow-hidden">
        <section
            id="contract"
            class="package-section background-shape-right ptb-100"
        >
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-8">
                        <div class="section-heading text-center mb-5">
                            <h2 style="font-size: 3.2em; color: #315192">
                               Contract Types
                            </h2>
                            <p class="lead">
                            Choose the best one for you
                            </p>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="col-lg-4 col-md">
                        <div class="card text-center single-pricing-pack" style="height:550px">
                            <div class="pt-4"><h5>Time & Materials</h5></div>
                            <div class="pricing-img mt-4">
                                <img
                                    src="img/diveria-img-svg/time-materials.svg"
                                    alt="pricing img"
                                    class="img-fluid"
                                />
                            </div>
                            <div class="card-body">
                                <ul
                                    class="list-unstyled text-sm mb-4 pricing-feature-list"
                                >
                                    <li><i class="fas fa-check"></i> Changing requirements</li>
                                    <li><i class="fas fa-check"></i> Flexibility in time & budget</li>

                                </ul>
                                <p style="color:black">
                                    These are all attributes that have a
                                    better fit in a Time & Materials contract.
                                    We will bill the project by the resources and
                                    time spent by our engineers during<br/> the contract.
                                </p>
                            <router-link to="#contact" v-scroll-to="'#contact'" class="btn cta-btn mb-3">
                                Contact Us
                            </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md">
                        <div class="card text-center single-pricing-pack" style="height:550px">
                            <div class="pt-4"><h5>Fixed Price</h5></div>
                            <div class="pricing-img mt-4">
                                <img
                                    src="img/diveria-img-svg/fixed-price.svg"
                                    alt="pricing img"
                                    class="img-fluid"
                                />
                            </div>
                            <div class="card-body">
                                <ul
                                    class="list-unstyled text-sm mb-4 pricing-feature-list"
                                >
                                    <li><i class="fas fa-check"></i> Managed change requests</li>
                                    <li><i class="fas fa-check"></i> Specified requirements</li>
                                </ul>
                                <p style="color:black">
                                    You send your specifications to us and
                                    we will give you an estimated cost of the project.
                                    The more complete the requirements, the more accurate the estimate.
                                </p>
                            <router-link to="#contact" v-scroll-to="'#contact'" class="btn cta-btn mb-3">
                                Contact Us
                            </router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mt-5 text-center">
                    <p class="mb-2">
                        If you need custom services or Need more?
                        <router-link to="#contact" v-scroll-to="'#contact'" class="color-secondary">
                            Contact Us
                        </router-link>
                    </p>
                </div>
                <!--pricing faq start-->
                <div class="row mt-5">
                    <div class="col-lg-6">
                        <accordian id="accordion-1" :contents="contents" />
                    </div>
                    <div class="col-lg-6">
                        <accordian id="accordion-2" :contents="contents1" />
                    </div>
                </div>
                <!--pricing faq end-->
            </div>
        </section>
    </div>
</template>

<script>
import axios from 'axios';
import Accordian from '../../components/Accordian.vue';

export default {
	name: 'About',
	isGray: {
		type: Boolean,
		default: false,
	},
	isPrimary: {
		type: Boolean,
		default: false,
	},
	components: {
		Accordian,
	},
	props: {
		URL: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			contents: [],
			contents1: [],
			show: false,
			error: '',
		};
	},
	async mounted() {
		try {
			const response = await axios.get(`${this.URL}/faqs`);
			const data = await response.data;
			const middleIndex = Math.ceil(data.length / 2);
			this.contents = data.splice(0, middleIndex);
			this.contents1 = data.splice(-middleIndex);
			this.show = true;
		} catch (e) {
			this.error = e;
		}
	},
};
</script>

<style>
</style>
