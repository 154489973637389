<template>
    <header class="header">
        <!--start navbar-->
                <nav
            class="navbar navbar-expand-lg fixed-top"
            v-bind:class="{
                affix: hasAffix,
                'custom-nav': coloredLogo,
                'bg-transparent': !coloredLogo,
                'white-bg': coloredLogo,
            }"
        >
            <div class="container">
                <a class="navbar-brand" href="/">
                    <img
                        src='img/diveria-img/diveria_logo_web.png'
                        alt="logo"
                        class="img-fluid hide-mobile"
                    />
                    <img
                        src='img/diveria-img/diveria_logo_mobile.png'
                        alt="logo"
                        class="img-fluid show-mobile"
                    />
                </a>
                <div>
                    <router-link style="opacity: 1 !important;" to="#contact" v-scroll-to="'#contact'">
                        <p class="bold contact-mobile">Contact</p>
                    </router-link>
                </div>
                <button
                    class="navbar-toggler"
                    type="button"
                    @click="mobileNavClicked"
                    v-bind:class="{ collapsed: collapsed }"
                >
                    <span class="ti-menu"></span>
                </button>

                <div
                    class="collapse navbar-collapse main-menu h-auto"
                    v-bind:class="{ show: !collapsed }"
                    id="navbarSupportedContent"
                >
                    <ul class="navbar-nav ml-auto">
                        <li class="nav-item nav-link page-scroll">
                            <router-link to="/" v-scroll-to="'#banner-diveria'" >
                                <p class="bold">Home</p>
                            </router-link>
                        </li>
                        <li class="nav-item nav-link page-scroll">
                            <router-link to="#what-we-do" v-scroll-to="'#what-we-do'">
                                <p class="bold">What we do</p>
                            </router-link>
                        </li>

                        <li class="nav-item nav-link page-scroll">
                            <router-link to="#why-diveria" v-scroll-to="'#why-diveria'">
                                <p class="bold">Why Diveria?</p>
                            </router-link>
                        </li>

                        <li class="nav-item nav-link page-scroll">
                            <router-link to="#outsourcing" v-scroll-to="'#outsourcing'">
                                <p class="bold">Outsourcing</p>
                            </router-link>
                        </li>

                        <li>
                            <router-link to="#contract" v-scroll-to="'#contract'">
                               <p class="bold">Contract Types</p>
                            </router-link>
                        </li>

                        <!-- <li class="nav-item nav-link page-scroll">
                            <router-link to="/join-us">
                                <p class="bold">Join us</p>
                            </router-link>
                        </li> -->

                        <li class="nav-item nav-link page-scroll">
                            <router-link style="opacity: 1 !important;" to="#contact" v-scroll-to="'#contact'">
                               <p class="bold contact">Contact</p>
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
        <!--end navbar-->
    </header>
</template>

<script>
export default {
	props: {
		coloredLogo: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			windowTop: 0,
			collapsed: true,
			white_bg: false,
		};
	},
	mounted() {
		window.addEventListener('scroll', this.onScroll);
	},
	beforeDestroy() {
		window.removeEventListener('scroll', this.onScroll);
	},
	methods: {
		onScroll() {
			this.windowTop = window.top.scrollY;
		},
		mobileNavClicked() {
			this.collapsed = !this.collapsed;
		},
	},
	computed: {
		hasAffix() {
			return this.windowTop > 0;
		},
	},
};
</script>

<style scoped>
    .bold{
        font-size: 1.1rem;
        font-weight: 500;
        margin-bottom: 0;
    }
    .contact {
        border-radius: 50px;
        background: #8bc34a;
        padding: 8px 20px;
        margin-top: -8px;
        color: #fff;
        box-shadow: inset 20px 20px 60px rgb(118, 166, 63),
                    inset -20px -20px 60px rgb(160, 224, 85);
        -webkit-box-shadow: inset 20px 20px 60px rgb(118, 166, 63),
                    inset -20px -20px 60px rgb(160, 224, 85);
        font-weight: 600;
        letter-spacing: 0.5px;
    }
    .hide-mobile{
        display: block;
    }
    .show-mobile{
        display: none;
    }
    .contact-mobile {
        display: none;
    }

    @media (min-width: 320px) and (max-width: 992px) {
        .contact {
            display: none;
        }
        .hide-mobile{
            display: none;
        }
        .show-mobile{
            display: block;
            margin-bottom: 5px;
        }
        .contact-mobile {
            display: block;
            border-radius: 50px;
            background: #8bc34a;
            padding: 8px 20px;
            color: #fff;
            box-shadow: inset 20px 20px 60px rgb(118, 166, 63),
                        inset -20px -20px 60px rgb(160, 224, 85);
            -webkit-box-shadow: inset 20px 20px 60px rgb(118, 166, 63),
                        inset -20px -20px 60px rgb(160, 224, 85);
            font-weight: 600;
            letter-spacing: 0.5px;
        }
    }
</style>
