import Vue from 'vue';
import './registerServiceWorker';
import VueGtag from 'vue-gtag';
// ------------ css ----------
import './assets/css/bootstrap.min.css';
import './assets/css/themify-icons.css';
import './assets/css/animate.min.css';
import './assets/css/main.css';
import './assets/css/responsive.css';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import VueScrollTo from 'vue-scrollto';
import { gsap } from 'gsap';
import { CSSRulePlugin } from 'gsap/CSSRulePlugin';

import jQuery from 'jquery';
// ------animaciones --------
import VueTypedJs from 'vue-typed-js';
import AOS from 'aos';
import VueNumber from 'vue-number-animation';
import store from './store';
import router from './router';
import 'aos/dist/aos.css';
import App from './App.vue';

window.$ = jQuery;

Vue.use(VueTypedJs);
Vue.use(VueNumber);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

Vue.use(VueGtag, {
	config: { id: 'UA-40316632-1' },
}, router);

Vue.use(VueScrollTo);

gsap.registerPlugin(CSSRulePlugin);

Vue.config.productionTip = false;

new Vue({
	created() {
		AOS.init();
	},
	router,
	store,
	render: (h) => h(App),
}).$mount('#app');
