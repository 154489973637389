<template>
	<section id="what-we-do" class="about-us pt-100">
		<div class="container">
			<h2 style="font-size: 3.2em; color: #315192">
				What we do
			</h2>

			<div class="row align-items-center justify-content-between flex-column-reverse flex-md-row">

				<div class="col-md-7">
					<div class="about-content-left section-heading">
						<div class="col-lg-16 col-md-push-5">
							<accordian id="accordion-3" :contents="Products" />
						</div>
					</div>
				</div>

				<div class="col-md-5">
					<div class="about-content-right">
						<img
							src="img/diveria-img-svg/products-img.svg"
							alt="about us"
							class="img-fluid animation-one"
						/>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import axios from 'axios';
import Accordian from '../../components/Accordian.vue';

export default {
	name: 'About',
	components: {
		Accordian,
	},
	props: {
		URL: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			Products: [],
		};
	},
	async mounted() {
		axios.get(`${this.URL}/product-generals`)
			.then(({ data }) => {
				this.Products = [...data[0].products];
			})
			.catch((error) => {
				this.error = error;
			});
	},
};
</script>

<style>
	.invert {
		filter: sepia(50%);
		border-radius: 5px;
		width: 50px;
		height: 50px;
	}
	.gris {
		margin: 0;
		padding-right: 10px;
		font-size: 16px;
		font-weight: 800;
		color: #757575;
	}
	.products {
		margin: 0;
	}
</style>
