<template>
	<section id="why-diveria" class="feature-section ptb-100 mb-5">
		<div v-if="mostrar" class="container">
					<div class="feature-contents section-heading">
						<h2 style="font-size: 3.2em; color: #315192; textAlign: center;">
							Why Diveria?
						</h2>
						<p class="lead" style="textAlign: center;">
							Choose the best option to work with us.
						</p>
						<div class="row">
							<ul class="list-inline counter-wrap" style="z-index:10;">
								<li
									v-for="counter in Counter1"
									:key="counter.id"
									class="list-inline-item"
								>
									<div class="single-counter text-center">
										<span>+</span>
										<number v-if="counter.id!==4"
											:from="0"
											:to="counter.number"
											:duration="5"
											:delay="2"
											easing="Power1.easeOut"
										/>
										<number v-if="counter.id===4"
											:from="0"
											:to="date-counter.number"
											:duration="5"
											:delay="2"
											easing="Power1.easeOut"
										/>
										<h5 class="gris">{{ counter.title }}</h5>
										<h6>{{ counter.subtitle }}</h6>
									</div>
								</li>
							</ul>
						</div>

						<div class="row mt-5">
							<ul class="counter-wrap" style="z-index:10;" >

								<li
									v-for="counter in Counter2"
									:key="counter.id"
									class="list-inline-item"
								>
									<div class="single-counter text-center">
										<span><i v-bind:class="counter.icono"></i></span>
										<h5 class="gris mt-2">{{counter.title}}</h5>
										<h6>{{counter.subtitle}}</h6>
									</div>

								</li>

							</ul>
						</div>
					</div>
		</div>
	</section>
</template>

<script>
import axios from 'axios';

export default {
	name: 'About-Us',
	props: {
		URL: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			Counter1: [],
			Counter2: [],
			error: '',
			date: 0,
			mostrar: false,
		};
	},
	async mounted() {
		try {
			const response = await axios.get(`${this.URL}/counters`);
			this.Counter1 = await response.data;
			const response1 = await axios.get(`${this.URL}/counters-2-s`);
			this.Counter2 = await response1.data;
			this.date = new Date().getFullYear();
			this.mostrar = true;
		} catch (e) {
			this.error = e;
		}
	},
};
</script>

<style></style>
