<template>
    <section
        id="contact"
        class="contact-us ptb-100 gray-light-bg"
    >
        <div class="container">
            <div class="row">
                <div
                    class="col-12 pb-3 message-box d-done"
                    v-bind:class="{
                        'd-none': !isSuccess && !hasError,
                        'd-block': isSuccess || hasError,
                    }"
                >
                    <div
                        class="alert"
                        v-bind:class="{
                            'alert-danger': hasError,
                            'alert-success': isSuccess,
                        }"
                    >
                        {{alertText}}
                    </div>
                </div>
                <div class="col-md-5">
                    <div class="section-heading">
                        <h3>Contact us</h3>
                        <p>
                            It's very easy to get in touch with us. Just use the
                            contact form or pay us a visit for a coffee at the
                            office.
                        </p>
                    </div>
                    <div class="footer-address">
                        <h6><strong>Head Office</strong></h6>
                        <p>200 South Biscayne Boulevard<br/>Miami FL 33131</p>
                        <ul>
                            <li><span>Phone: <a href="tel:+16469706875"> +1 (646) 970-6875</a></span></li>
                            <li>
                                <span>
                                    Email :
                                    <a href="mailto:info@diveria.com">
                                        info@diveria.com
                                    </a>
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-7">
                    <form
                        @submit.prevent="sendEmail"
                        id="contactForm"
                        class="contact-us-form"
                    >
                        <h5>Reach us quickly</h5>
                        <div class="row">
                            <div class="col-sm-6 col-12">
                                <div class="form-group">
                                    <input
                                        type="text"
                                        class="form-control"
                                        name="from_name"
                                        placeholder="Enter name"
                                        required="required"
                                    />
                                </div>
                            </div>
                            <div class="col-sm-6 col-12">
                                <div class="form-group">
                                    <input
                                        type="email"
                                        class="form-control"
                                        name="reply_to"
                                        placeholder="Enter email"
                                        required="required"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-6 col-12">
                                <div class="form-group">
                                    <input
                                        type="text"
                                        name="phone"
                                        value=""
                                        class="form-control"
                                        placeholder="Your Phone"
                                        required="required"
                                    />
                                </div>
                            </div>
                            <div class="col-sm-6 col-12">
                                <div class="form-group">
                                    <input
                                        type="text"
                                        name="company"
                                        value=""
                                        size="40"
                                        class="form-control"
                                        placeholder="Your Company"
                                        required="required"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <textarea
                                        name="message"
                                        class="form-control"
                                        rows="7"
                                        cols="25"
                                        placeholder="Message"
                                        required="required"
                                    ></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12 mt-3">
                                <button
                                    type="submit"
                                    class="btn solid-btn disabled"
                                    id="btnContactUs"
                                    style="pointer-events: all; cursor: pointer"
                                >
                                    Send Message
                                </button>
                            </div>
                        </div>
                    </form>
                    <p class="form-message"></p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import emailjs from 'emailjs-com';

export default {
	name: 'Contact',
	data() {
		return {
			error: '',
			alertText: '',
			hasError: false,
			isSuccess: false,
		};
	},
	methods: {
		sendEmail(e) {
			emailjs.sendForm('service_yburqu9', 'template_5ryf8v9', e.target, 'user_9FaIhhzEsvuUXcxYrzJBj')
				.then((response) => {
					if (response.status === 200) {
						this.alertText = 'Thank you for contacting us! We will reply as soon as possible.';
						this.hasError = false;
						this.isSuccess = true;
						e.target.reset();
					} else {
						this.alertText = 'An error has occurred, please try again.';
						this.isSuccess = false;
						this.hasError = true;
					}
				})
				.catch((error) => {
					this.error = error;
					this.alertText = 'An error has occurred, please try again.';
					this.isSuccess = false;
					this.hasError = true;
				});
		},
		mounted() {
			this.alertText = '';
			this.hasError = false;
			this.isSuccess = false;
		},
	},
};
</script>

<style>
</style>
