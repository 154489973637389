<template>
	<footer id="footer" class="footer-section">
		<div class="bg-footer-custom footer-top pt-5 pb-5 background-img-3">
			<div class="container">
				<div class="row justify-content-between">
					<div class="col-lg-3 mb-3 mb-lg-0">
						<div class="footer-nav-wrap text-white">
							<h5 class="mb-3 text-white">Follow Us!</h5>
							<p>
								At Diveria, we believe that any software project is possible. We have the knowledge, skills, and experience to provide any critical software solution.
							</p>

							<div class="social-list-wrap">
								<ul class="social-list list-inline">
									<li class="list-inline-item">
										<a
											href="https://www.facebook.com/Diveria"
											target="_blank"
											title="Facebook"
											><span class="ti-facebook" style="font-size:25px;"></span
										></a>
									</li>
									<li class="list-inline-item">
										<a
											href="https://twitter.com/diveria"
											target="_blank"
											title="Twitter"
											><span class="ti-twitter" style="font-size:25px;"></span
										></a>
									</li>
									<li class="list-inline-item">
										<a
											href="https://www.linkedin.com/company/diveria"
											target="_blank"
											title="Linkedin"
											><span class="ti-linkedin" style="font-size:25px;"></span
										></a>
									</li>
									<li class="list-inline-item">
										<a
											href="https://www.instagram.com/diveriasrl"
											target="_blank"
											title="Instagram"
											><span class="ti-instagram" style="font-size:25px;"></span
										></a>
									</li>
									<li class="list-inline-item">
										<a
											href="https://www.youtube.com/channel/UCCxsnuwHyTfXDnuhEGUrXIw"
											target="_blank"
											title="Youtube"
											><span class="ti-youtube" style="font-size:25px;"></span
										></a>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div class="col-lg-3 ml-auto mb-4 mb-lg-0">
						<div class="footer-nav-wrap text-white">
						</div>
					</div>
					<div class="col-lg-3 ml-auto mb-4 mb-lg-0">
						<div class="footer-nav-wrap text-white">
							<h5 class="mb-3 text-white">Location</h5>
							<ul class="list-unstyled support-list">
								<li class="mb-2 d-flex align-items-center">
									<span class="ti-location-pin mr-2"></span>200 South Biscayne Boulevard
									Miami<br /> FL 33131
								</li>
								<li class="mb-2 d-flex align-items-center">
									<span class="ti-mobile mr-2"></span>
									<a href="tel:+16469706875"> +1 (646) 970-6875</a>
								</li>
								<li class="mb-2 d-flex align-items-center">
									<span class="ti-email mr-2"></span
									><a href="mailto:info@diveria.com"> info@diveria.com</a>
								</li>
							</ul>
						</div>
					</div>
					<div class="col-lg-3">
						<div class="footer-nav-wrap text-white">
							<div class="embed-resposive embed-resposive-16by9">
								<iframe
									class="embed-responsive-item"
									src="https://maps.google.com/maps?q=200%20South%20Biscayne%20Boulevard&t=&z=15&ie=UTF8&iwloc=&output=embed"
									frameborder="0"
									style="border:0;"
									allowfullscreen=""
									aria-hidden="false"
									tabindex="0"
								></iframe>
							</div>
							<!-- <div id="linkedin_follow">
							</div> -->
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="footer-bottom gray-light-bg pt-4 pb-4">
			<div id="box" class="box"> </div>
			<div id="heart" class="heart"></div>
			<div class="container" >
				<div class="row text-center justify-content-center">
					<div class="col-md-6 col-lg-5">
						<p class="copyright-text pb-0 mb-0">
							Copyright © 2021 All rights reserved by
							<a href="#">Diveria</a>
							CUIT 30-71183025-8
						</p>
					</div>
				</div>
			</div>
		</div>
	</footer>
</template>

<script>
export default {
	name: 'SiteFooter',
	mounted() {
		const box = document.getElementById('box');
		const heart = document.getElementById('heart');

		box.addEventListener('click', () => {
			console.log(heart);
			heart.classList.add('animate-up');
		});
	// 	const linkedinScript = document.createElement('script');
	// 	linkedinScript.setAttribute('src', 'https://platform.linkedin.com/in.js');
	// 	linkedinScript.setAttribute('type', 'text/javascript');
	// 	linkedinScript.appendChild(document.createTextNode('lang: en_US'));
	// 	document.getElementById('linkedin_follow').appendChild(linkedinScript);
	// 	const followScript = document.createElement('script');
	// 	followScript.setAttribute('type', 'IN/FollowCompany');
	// 	followScript.setAttribute('data-id', '0000');
	// 	followScript.setAttribute('data-counter', 'right');
	// 	document.getElementById('linkedin_follow').appendChild(followScript);
	},
};

</script>

<style scoped lang="scss">
	.bg-footer-custom {
		background: url("/img/diveria-img/footer-bg.png") no-repeat center top / cover !important;
	}
	.box {
		height:5px;
		width: 5px;
		position: relative;
		bottom: -4rem;
		left: 2rem;
		z-index: 3;
		background-color: #f4f7fa;;
		cursor: pointer;
	}

	.heart {
		position: relative;
		bottom: -4rem;
		left: 2.3rem;
		transform: translate(-50%, -50%);
		z-index: 1;
		background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIyMHB4IiBoZWlnaHQ9IjE4cHgiIHZpZXdCb3g9IjAgMCAyMCAxOCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4gICAgICAgIDx0aXRsZT5oZWFydDwvdGl0bGU+ICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPiAgICA8ZGVmcz48L2RlZnM+ICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPiAgICAgICAgPGcgaWQ9ImhlYXJ0IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNi4wMDAwMDAsIC03LjAwMDAwMCkiIGZpbGw9IiNEMDAyMUIiPiAgICAgICAgICAgIDxwYXRoIGQ9Ik0xNS41MjM4MDk1LDI1IEMxNiwyNSAxNiwyNSAxNi40NzYxOTA1LDI1IEwyNC4wOTUyMzgxLDE3IEMyNi41NDk4Njc5LDE0LjUxMzUwNDIgMjYuNTQ5ODY3OSwxMC45MDAxNDM2IDI0LjA5NTIzODEsOSBDMjIuMTUwOTIzNSw2LjQ0MjgzNjc3IDE4LjU4NDg3NSw2LjQ0MjgzNjc3IDE2LjQ3NjE5MDUsOSBDMTYsOSAxNiw5IDE1LjUyMzgwOTUsOSBDMTMuNDE1MTI1LDYuNDQyODM2NzcgOS44NDkwNzY0Niw2LjQ0MjgzNjc3IDcuOTA0NzYxOSw5IEM1LjQ1MDEzMjA3LDEwLjkwMDE0MzYgNS40NTAxMzIwNywxNC41MTM1MDQyIDcuOTA0NzYxOSwxNyBMMTUuNTIzODA5NSwyNSBaIj48L3BhdGg+ICAgICAgICA8L2c+ICAgIDwvZz48L3N2Zz4=");
		background-repeat: no-repeat;
		height:22px;
		width:22px;
		opacity: 0;
	}

	@mixin animation($name, $duration, $delay, $count) {
		-webkit-animation: $name $duration $delay $count;
		-moz-animation: $name $duration $delay $count;
		-o-animation: $name $duration $delay $count;
		-ms-animation: $name $duration $delay $count;
		animation: $name $duration $delay $count;
	}

	.animate-up {
		position: relative;
		@include animation(moveup, 3s, 0s, 1);
	}

	@keyframes moveup {
		0% {
			bottom: -4rem;
			opacity: 0;
		}
		50% {
			bottom: -2rem;
			opacity: 0.9;
		}
		100% {
			bottom: 0rem;
			opacity: 0;
		}
	}

</style>
