<template>
	<div>
		<nav-bar />
		<div class="main">
			<banner :URL="URL" />
			<products :URL="URL" />
			<about-us :URL="URL" />
			<services :URL="URL" />
			<pricing :URL="URL" />
			<testimonial :URL="URL" />
			<contact />
			<site-footer />
		</div>
	</div>
</template>

<script>
import NavBar from '../../views/common/NavBar.vue';
import Banner from '../../views/common/Banner.vue';
import Services from '../../views/diveria-index/Services.vue';
import Products from '../../views/diveria-index/Products.vue';
import AboutUs from '../../views/diveria-index/AboutUs.vue';
import Pricing from '../../views/diveria-index/Pricing.vue';
import Testimonial from '../../views/diveria-index/Testimonial.vue';
import Contact from '../../views/diveria-index/Contact.vue';
import SiteFooter from '../../views/common/SiteFooter.vue';
import url from '../../../public/url.txt';

export default {
	name: 'DiveriaIndex',
	components: {
		NavBar,
		Banner,
		Services,
		Products,
		AboutUs,
		Pricing,
		Testimonial,
		Contact,
		SiteFooter,
	},
	data() {
		return {
			URL: url,
		};
	},
};
</script>
