<template>
	<section style="background-image: linear-gradient(to right, #1e5799 0%,#207cca 40%,#207cca 40%,#2989d8 63%,#7db9e8 100%);">
		<div id="hubspotForm" style="padding-top: 10%; margin-left: 10%; margin-right: 10%; padding-bottom: 5%;">
			<script type="application/javascript">
				const script = document.createElement('script');
				script.type = 'text/javascript';
				script.src = 'https://js.hsforms.net/forms/shell.js';
				const div = document.getElementById('hubspotForm');
				div.appendChild(script);
				script.addEventListener('load', () => {
					if (window.hbspt) {
						window.hbspt.forms.create({
							region: 'na1',
							portalId: '7377215',
							formId: '442d7b96-abe4-428f-95c3-484ff6355033',
						});
						let form = document.getElementsByClassName("hbspt-form");
						form[0].style.backgroundColor = 'white';
						form[0].style.padding = '16px';
					}
				});
			</script>
		</div>
	</section>

</template>

<script>
export default {
	name: 'SetupElement',
};

</script>
