import Vue from 'vue';
import VueRouter from 'vue-router';

import DiveriaIndex from '../pages/indices/DiveriaIndex.vue';
// import JoinUs from '../pages/indices/JoinUs.vue';
import Setup from '../pages/indices/Setup.vue';
import Page404 from '../pages/indices/Page404.vue';

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		name: 'DiveriaIndex',
		component: DiveriaIndex,
		meta: { title: 'Diveria | Home' },
	},
	// {
	// 	path: '/join-us',
	// 	name: 'JoinUs',
	// 	component: JoinUs,
	// 	meta: { title: 'Diveria | Join Us' },
	// },
	{
		path: '/setup',
		name: 'Setup',
		component: Setup,
		meta: { title: 'Diveria | Setup' },
	},
	{
		path: '/*',
		name: '404',
		component: Page404,
		meta: { title: 'Diveria | 404' },
	},
];

const router = new VueRouter({
	mode: 'history',
	routes,
	scrollBehavior(to, from, savedPosition) {
		if (to.hash) {
			let id = to.hash;
			id = id.replace(/#/g, '');
			const element = document.getElementById(id);
			if (element) {
				const retorno = setTimeout(
					() => window.scrollTo({
						top: element.offsetTop,
						behavior: 'smooth',
					}),
					1500,
				);
				return retorno;
			}
		} else if (savedPosition) {
			return savedPosition;
		} else {
			return { x: 0, y: 0 };
		}
		return { x: 0, y: 0 };
	},
});

router.beforeEach((to, from, next) => {
	if (to.meta && to.meta.title) document.title = to.meta.title;
	next();
});

export default router;
