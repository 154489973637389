<template>
	<div>
		<nav-bar />
		<div class="main">
			<setup-element />
			<site-footer />
		</div>
	</div>
</template>

<script>
import NavBar from '../../views/common/NavBar.vue';
import SiteFooter from '../../views/common/SiteFooter.vue';
import SetupElement from '../../views/setup/SetupElement.vue';

export default {
	name: 'Setup',
	components: {
		NavBar,
		SetupElement,
		SiteFooter,
	},
};
</script>
