<template>
	<section class="ptb-100 background-img banner-1-bg">
		<div class="container">
			<div class="row align-items-center justify-content-between py-5">
				<div class="col-md-7 col-lg-6">
					<div class="hero-content-left text-white">
						<h1 data-aos="fade-right" data-aos-delay="500" class="text-white">
							<span>Get your Software Solutions DONE</span>
						</h1>
						<vue-typed-js
							data-aos="zoom-in"
							data-aos-delay="500"
							:strings="['Dynamic', 'Diligent', 'Dependable', 'DIVERIA']"
							:cursorChar="'_'"
							:typeSpeed="100"
							:backSpeed="100"
							:stringsElement="'myId'"
							:loop="true"
						>
							<h2 style="color:white;">We are <span class="typing"></span></h2>
						</vue-typed-js>
						<a href="#contact" class="button btn cta-btn">Get Started</a>
					</div>
				</div>
				<div class="col-md-5 col-lg-5">
					<div class="hero-animation-img">
						<img
							data-aos="zoom-out"
							data-aos-delay="500"
							src="img/diveria-img-svg/banner-img.svg"
							alt="app"
							class="img-fluid d-block m-auto animation-one"
						/>
					</div>
				</div>
			</div>
		</div>
		<div class="bottom-img-absolute">
			<img
				src="img/diveria-img-svg/hero-bg-shape-1.svg"
				alt="wave shape"
				class="img-fluid"
			/>
		</div>
	</section>
</template>

<script>
export default {
	name: 'Banner',
};
</script>

<style scoped>
	.banner-1-bg {
		background: url("/img/diveria-img/background-banner.jpg") no-repeat center
			center / cover !important;
	}
	.gris {
		margin: 0;
		font-size: 16px;
		color: #757575;
	}
</style>
