<template>
	<section id="outsourcing" class="promo-section ptb-100 imagen background-img-2">
		<div class="container">
			<div class="row justify-content-center">
				<div class="col-lg-7 col-md-8">
					<div class="section-heading text-center mb-5">
						<h2 style="font-size: 3.2em; color: #315192">Nearshore Outsourcing Models</h2>
					</div>
				</div>
			</div>
			<div class="row" style="justify-content:space-between; margin:auto;">
				<div v-for="(service, index) in Services.services" :key="index" class="col-md-4 col-lg-4" style="height:450px; width:332px; margin:auto;">
					<vue-flip active-click width="300px" height="300px" style="margin:auto;">
						<template v-slot:front>
							<div
								style="cursor:pointer; margin:auto;"
								class=" text-center single-promo single-promo-1 rounded white-bg p-5"
							>
								<div class="circle-icon mb-5">
									<span class="text-white"><i v-bind:class="service.icono"></i></span>
								</div>
								<h5>{{ service.title }}</h5>
								<p>
									{{ service.subtitle }}
								</p>
							</div>
						</template>
						<template v-slot:back style="background-color:white" >
							<div
								style="cursor:pointer; width:300px; height:385px;"
								class=" text-center single-promo  single-promo-1 rounded white-bg p-5"
							>
								{{ service.description }}
							</div>
						</template>
					</vue-flip>
				</div>
			</div>
		</div>
	</section>
</template>
<script>
import axios from 'axios';
import VueFlip from 'vue-flip';

export default {
	name: 'Services',
	components: { 'vue-flip': VueFlip },
	props: {
		URL: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			Services: [],
			error: '',
		};
	},
	async mounted() {
		try {
			const response = await axios.get(`${this.URL}/services-generals`);
			// eslint-disable-next-line prefer-destructuring
			this.Services = response.data[0];
		} catch (e) {
			this.error = e;
		}
	},
};
</script>

<style scoped>
	.imagen {
		background: url("/img/diveria-img-svg/services-img.svg") no-repeat center !important;
	}

</style>
